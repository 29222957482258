import { Component, Input, OnInit, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from '@angular/core'

@Component({
  selector: 'ui-interview-question-tags',
  templateUrl: './interview-question-tags.component.html',
  styleUrls: ['./interview-question-tags.component.scss'],
})
export class InterviewQuestionTagsComponent implements OnInit {
  @Input() tagList?: string[]

  constructor() {}

  ngOnInit(): void {}
}
