<e11-panel>
  <div class="row">
    <div class="col-xs-12 text-center">
      <e11-loader-dots *ngIf="isLoading" [size]="'sm'" [color]="'e11-bg-skin-primary-accent'" label="{{ ' ' | translate }}"></e11-loader-dots>

      <div *ngIf="!isLoading">
        <!-- Upload image -->
        <div class="avatar-upload">
          <div class="avatar-edit">
            <input type="file" id="imageUpload" accept=".png, .jpg, .jpeg" #fileInput (change)="uploadFileAT($event)" />
          </div>
          <div class="avatar-preview">
            <div id="imagePreview" [style.backgroundImage]="'url(' + imageUrl + ')'"></div>
          </div>
        </div>
      </div>

      <e11-button id="button_edit" value="{{ 'Edit logo' | translate }}" color="primary-accent" [type]="'button'" (click)="modalProfilePic.open()">
      </e11-button>
      <div class="spacer-2rem"></div>
      <p class="text-grey">{{ 'Profile Created:' | translate }} 10/18/20</p>
    </div>
  </div>
</e11-panel>

<!-- Modal -->
<e11-modal #modalProfilePic [width]="'lg'" [showHeader]="false" [closer]="true" [showFooter]="false">
  <div modal-body>
    <div class="row">
      <div class="col-xs-12 text-center">
        <!-- <div class="group-gap"> -->

        <!-- Upload image -->
        <div class="avatar-upload">
          <div class="avatar-edit">
            <input type="file" id="imageUpload" accept=".png, .jpg, .jpeg" #fileInput (change)="uploadFileAT($event)" />
          </div>
          <div class="avatar-preview">
            <div id="imagePreview" [style.backgroundImage]="'url(' + imageUrl + ')'"></div>
          </div>
        </div>
        <!-- </div> -->
        <!-- <p class="nomargin-top">{{ 'Current photo' | translate }}</p> -->
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12">
        <h4>{{ 'Change Photo' | translate }}</h4>
        <p>{{ 'Update your company profile picture' | translate }}</p>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 text-center">
        <e11-button
          id="button_upload"
          value="{{ 'Upload from computer' | translate }}"
          color="primary-accent"
          [type]="'button'"
          [style]="'block'"
          [icon]="'file_upload'"
          [iconPosition]="'left'"
          (click)="fileInput.click()"
        >
        </e11-button>
        <div class="spacer-1rem"></div>
        <e11-button
          id="button_submit"
          value="{{ 'Remove photo' | translate }}"
          color="secondary"
          [type]="'button'"
          [style]="'block'"
          [icon]="'cancel'"
          [iconPosition]="'left'"
          (click)="removeUploadedFile()"
        >
        </e11-button>
        <div class="spacer-1rem"></div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-6 text-right nopadding">
        <e11-button
          id="button_cancel"
          value="{{ 'Cancel' | translate }}"
          [color]="'neutral'"
          [type]="'button'"
          [buttonGroup]="true"
          [ghost]="true"
          (click)="modalProfilePic.close()"
        >
        </e11-button>
      </div>
      <div class="col-xs-6 nopadding">
        <e11-button id="button_save" value="{{ 'Save' | translate }}" [color]="'primary-accent'" [type]="'button'" (click)="modalProfilePic.close()">
        </e11-button>
      </div>
    </div>
  </div>
</e11-modal>
