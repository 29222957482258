<e11-panel>
  <div class="row">
    <div class="col-xs-12 text-center">
      <form [formGroup]="registrationForm" (ngSubmit)="onSubmit()">
        <div class="group-gap">
          <div class="avatar-upload">
            <div class="avatar-edit">
              <input type="file" id="imageUpload" accept=".png, .jpg, .jpeg" #fileInput (change)="uploadFile($event)" />
            </div>
            <div class="avatar-preview">
              <!-- <div id="imagePreview" [style.backgroundImage]="'url('+ imageUrl +')'"> -->
            </div>
          </div>
        </div>
        <p class="pointer e11-text-skin-primary-accent" (click)="modalProfilePic.open()">Change profile photo</p>

        <e11-button
          [ghost]="true"
          [size]="'sm'"
          [type]="'submit'"
          (click)="fileInput.click()"
          value="Upload Image"
          [buttonGroup]="true"
          [icon]="'file_upload'"
          iconPosition="left"
        >
        </e11-button>
        <e11-button
          [ghost]="true"
          [size]="'sm'"
          color="secondary"
          [type]="'submit'"
          (click)="removeUploadedFile()"
          value="Remove Image"
          [icon]="'cancel'"
          iconPosition="left"
        >
        </e11-button>
      </form>
      <p>Profile Created: 10/18/201</p>
    </div>
  </div>
</e11-panel>

<!-- Modal -->
<e11-modal #modalProfilePic [width]="'lg'" title="{{ 'Change Profile Photo' | translate }}" [showFooter]="false">
  <div modal-body>
    <div class="row">
      <div class="col-xs-12 text-center">
        <div class="profile-pic"></div>
        <!-- <p>{{ 'Current photo' | translate }}</p> -->
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12">
        <h4>{{ 'Profile.ChangePhoto' | translate }}</h4>
        <!-- <p>Update your profile picture</p> -->
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 text-center">
        <e11-button
          id="button_upload"
          value="{{ 'Upload from computer' | translate }}"
          color="primary-accent"
          [type]="'button'"
          [style]="'block'"
          [icon]="'file_upload'"
          [iconPosition]="'left'"
        >
        </e11-button>
        <div class="spacer-1rem"></div>
        <e11-button
          id="button_submit"
          value="{{ 'Remove photo' | translate }}"
          color="secondary"
          [type]="'button'"
          [style]="'block'"
          [icon]="'cancel'"
          [iconPosition]="'left'"
        >
        </e11-button>
        <div class="spacer-1rem"></div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-6 text-right nopadding">
        <e11-button id="button_cancel" value="{{ 'Cancel' | translate }}" [color]="'neutral'" [type]="'button'" [buttonGroup]="true" [ghost]="true">
        </e11-button>
      </div>
      <div class="col-xs-6 nopadding">
        <e11-button id="button_save" value="{{ 'Save' | translate }}" [color]="'primary-accent'" [type]="'button'"> </e11-button>
      </div>
    </div>
  </div>
</e11-modal>
