import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'customer-nav',
  template: `<sdk-customer-client-navigation></sdk-customer-client-navigation>`,
})
export class CustomerNavComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
