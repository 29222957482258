<ais-refinement-list
  [sortBy]="sortBy"
  [attribute]="attribute"
  [operator]="operator"
  [searchable]="searchable"
  [showMore]="showMore"
  [showMoreLimit]="showMoreLimit"
  [transformItems]="transform"
>
</ais-refinement-list>
