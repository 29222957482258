import { Pipe, PipeTransform } from '@angular/core'
import { documentIdToContentId } from '@engineering11/content-web'

@Pipe({
  name: 'documentIdToContentId',
})
export class DocumentIdToContentIdPipe implements PipeTransform {
  transform(docId: string): string {
    return documentIdToContentId(docId)
  }
}
