<div class="e11-w-full e11-flex e11-justify-between e11-mb-2">
  <p class="e11-font-primary-demibold e11-text-sm e11-mb-0">{{ 'Tags' | translate }}</p>
  <a class="e11-text-skin-primary-accent e11-text-sm e11-cursor-pointer">{{ '+ Add a Tag' | translate }}</a>
</div>
<e11-empty-state
  *ngIf="tagList!.length === 0"
  [icon]="'construction'"
  [iconSize]="'md-36'"
  [clickable]="false"
  title="{{ 'No Tags' | translate }}"
  subtitle="{{ 'Add tags to your interview question for better organization!' | translate }}"
>
</e11-empty-state>
<div class="e11-flex e11-flex-wrap e11-items-center e11-px-4">
  <e11-chip *ngFor="let tag of tagList" [value]="tag" [icon]="'close'" [iconSize]="'md-14'" [iconClickable]="true"></e11-chip>
</div>
