import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { E11ModalGlobalService } from '@engineering11/ui-lib/e11-modal'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-modal-company-logo-upload',
  templateUrl: './company-logo-upload-modal.component.html',
  styleUrls: ['./company-logo-upload-modal.component.scss'],
})
export class CompanyLogoUploadModalComponent implements OnInit {
  @Input() logoUrl: string | null | undefined

  @Output() logoUploaded = new EventEmitter<File>()
  @Output() logoRemoved = new EventEmitter()

  fileForImageCropper!: File
  croppedImage!: File

  constructor() {}

  ngOnInit(): void {}

  imageCropped(event: any) {
    const extension = this.fileForImageCropper.name.split('.').pop()
    const newFilename = crypto.randomUUID() + '.' + extension

    this.croppedImage = new File([event.file], newFilename, { type: event.file.type })
  }

  setImageToCropper(event: any) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files
      this.fileForImageCropper = file
    }
  }
}
