import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'modal-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class ModalPrivacyPolicyComponent implements OnInit, OnChanges {
  @ViewChild('modalPrivacy') modalPrivacy: any
  @Input() status: boolean = false

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    this.openModal(changes.status.currentValue)
  }

  openModal(status: boolean) {
    this.modalPrivacy?.open()
  }
}
