import { Component, OnDestroy, OnInit } from '@angular/core'
import { AngularFireStorage } from '@angular/fire/compat/storage'
import { ConfigService } from '@employer/app/services/config.service'
import { SubscriptionManagementService } from '@employer/app/services/subscription.management.service'
import { IAuthService } from '@engineering11/auth-web'
import { CustomerService, IBusinessCustomer } from '@engineering11/customer-web'
import { E11Logger } from '@engineering11/web-api-error'
import firebase from 'firebase/compat/app'
import { TokenStorage } from 'shared-lib'

@Component({
  selector: 'customer-manage-profile-pic',
  templateUrl: './manage-profile-pic.component.html',
  styleUrls: ['./manage-profile-pic.component.scss'],
})
export class CustomerManageProfilePicComponent implements OnInit, OnDestroy {
  themeCurrent: any
  customer: Partial<IBusinessCustomer> = {}
  isLoading: boolean = true
  subs: SubscriptionManagementService = new SubscriptionManagementService()

  imageStorageBucket: string = '/devImages/profilePics/' // Make global? Configurable? Constant?
  imageUrl: any // Complains about being a string

  constructor(
    private configService: ConfigService,
    private authService: IAuthService,
    private tokenStorage: TokenStorage,
    private customerService: CustomerService,
    private afStorage: AngularFireStorage,
    private logger: E11Logger
  ) {
    this.themeCurrent = configService.config.theme
  }

  ngOnInit(): void {
    // NOTE: This component isn't complete!
    // The current image swapping is live/hot, meaning it isnt a 'preview' that you then save.
    // That work still needs to be done

    const userLocalStorage = this.tokenStorage.getItem('user')
    if (userLocalStorage) {
      this.subs.next = this.customerService.get(userLocalStorage.customerKey).subscribe(
        data => {
          const customer = data as IBusinessCustomer
          this.customer = customer
          if (customer.id) {
            this.imageUrl = customer!.logoImageURL
            this.isLoading = false
          } else {
            //
          }
        },
        // Handle this potential error
        error => this.logger.log(error)
      )
    } else {
      // We don't have a locally stored user object. Logout and re-route
      this.authService.signOut()
    }
  }

  // Upload file to firebase storage
  async uploadFileAT(event: any) {
    let file = event.target.files[0]
    const filePath = `${this.imageStorageBucket}/${file.name}`
    const snapshot = await this.afStorage.upload(filePath, file)
    this.getUrl(snapshot)
  }

  // Retrieve download url
  async getUrl(snapshot: firebase.storage.UploadTaskSnapshot) {
    this.imageUrl = await snapshot.ref.getDownloadURL()
    const updateData = { id: this.customer.id!, logoImageURL: this.imageUrl }
    this.customerService.update(updateData)
  }

  removeUploadedFile() {
    const updateData = { id: this.customer.id!, logoImageURL: '' }
    this.customerService.update(updateData)
    this.imageUrl = ''
  }

  ngOnDestroy(): void {
    this.subs.stop()
  }
}
