<ng-container *ngrxLet="features$ as features">
  <e11-panel>
    <div class="row">
      <div class="col-sm-12">
        <ul>
          <li class="main" [routerLink]="['/customer']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
            {{ 'Company Account' | translate }}
          </li>
          <li class="sub hover:e11-text-skin-primary-accent" (click)="navigateTo('#address')" [ngClass]="{ active: activeSection === '#address' }">
            {{ 'Account Information' | translate }}
          </li>
          <li class="sub hover:e11-text-skin-primary-accent" (click)="navigateTo('#logo')" [ngClass]="{ active: activeSection === '#logo' }">
            {{ 'Company Logo' | translate }}
          </li>
          <li
            class="sub hover:e11-text-skin-primary-accent"
            (click)="navigateTo('#disclaimer')"
            [ngClass]="{ active: activeSection === '#disclaimer' }"
          >
            {{ 'EEO Policy Statement' | translate }}
          </li>
          <li class="main" [routerLink]="['/customer/locations']" [routerLinkActive]="['active']">{{ 'Locations' | translate }}</li>
          <li class="main" [routerLink]="routes.SOCIAL_MEDIA" [routerLinkActive]="['active']">{{ 'Social Media' | translate }}</li>
          <ng-container *ngIf="(features$ | async)?.employerInterviewQuestions">
            <li class="main" [routerLink]="routes.IQ_LIBRARY" [routerLinkActive]="['active']">{{ 'Question Library' | translate }}</li>
          </ng-container>
          <li class="main" [routerLink]="['/customer/departments']" [routerLinkActive]="['active']">
            {{ 'Departments' | translate }}
          </li>
          <li class="main" [routerLink]="['/customer/careers']" [routerLinkActive]="['active']">
            {{ 'Careers Page' | translate }}
          </li>
        </ul>
      </div>
    </div>
  </e11-panel>
</ng-container>
