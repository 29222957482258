import { Component, OnInit } from '@angular/core'
import { ConfigService } from '@employer/app/services/config.service'

@Component({
  selector: 'customer-manage-industry',
  templateUrl: './manage-industry.component.html',
  styleUrls: ['./manage-industry.component.scss'],
})
export class CustomerManageIndustryComponent implements OnInit {
  themeCurrent: any
  // TEST - DEV Data
  testUser: any // Pretending I have a user here,   using the one I stored during registration

  constructor(private configService: ConfigService) {
    this.themeCurrent = configService.config.theme
  }

  ngOnInit(): void {}
}
