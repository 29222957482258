<div class="col-xs-12">
  <div class="card">
    <div class="row">
      <div class="col-xs-3">
        <img class="image" src="{{ item.photoURL }}" alt="{{ item.firstName }}" />

        <div class="detail">
          <h4 class="nomargin-bottom ellipsis">{{ item.firstName }} {{ item.lastName }}</h4>
          <div class="occupation ellipsis">{{ item.email }}</div>
          <div class="occupation ellipsis">{{ item.phoneNumber }}</div>
          <div class="occupation ellipsis">Dallas, Texas 75231</div>
        </div>
      </div>

      <div class="col-xs-3 text-center">
        <img class="military-badge" src="{{ item.photoURL }}" alt="{{ item.firstName }}" />
        <!-- If this is conditional then it may not always be the last child -->
        <div class="visa" *ngIf="item.visaSponsorshipRequired">Visa Sponsorship Required</div>
      </div>

      <div class="col-xs-3 text-center">
        <div>Applied {{ item.appliedDate | date : 'M/d/yy' }}</div>
        <div class="qa">
          <e11-button [size]="'sm'" color="secondary" [value]="'Q & A Response Received'"> </e11-button>
        </div>
        <div class="stars">
          <div *ngIf="item.starRating">
            <i class="fas fa-lg fa-star" *ngFor="let star of fullStars(item.starRating)"></i>
            <i class="far fa-lg fa-star" *ngFor="let star of emptyStars(item.starRating)"></i>
          </div>
        </div>
      </div>

      <div class="col-xs-1 charts">
        <div class="chart">
          <e11-chart-percentage [data]="item.jobMatchScore" color="primary" [id]="item.firstName + 'li'"></e11-chart-percentage>
        </div>
      </div>
      <div class="col-xs-1 charts">
        <div class="chart">
          <e11-chart-percentage [data]="item.coreMatchScore" color="secondary" [id]="item.lastName + 'li'"></e11-chart-percentage>
        </div>
      </div>

      <div class="col-xs-1 charts"></div>
    </div>
  </div>
</div>
