import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { ROUTES } from '@employer/app/services/navigation.service'
import { Observable } from 'rxjs'
import { CnectFeatures, ConfigStore } from 'shared-lib'

@Component({
  selector: 'customer-menu-left',
  templateUrl: './menu-left.component.html',
  styleUrls: ['./menu-left.component.scss'],
})
export class CustomerMenuLeftComponent implements OnInit {
  features$: Observable<CnectFeatures | undefined> = this.configStore.features$
  activeSection: string = ''
  url: string = ''
  routes = ROUTES

  @Output() scrollingTo = new EventEmitter()
  constructor(private router: Router, private route: ActivatedRoute, private configStore: ConfigStore) {}

  ngOnInit(): void {
    if (this.route.snapshot.url.join().split(',').length > 0) {
      this.url = this.route.snapshot.url.join().split(',')[0]
    }
  }

  navigateTo(id: string) {
    if (this.url) {
      this.router.navigateByUrl('/customer').then(() => {
        setTimeout(() => {
          this.scrollTo(id)
        }, 250)
      })
    } else {
      this.scrollTo(id)
    }
  }

  scrollTo(id: string): void {
    this.activeSection = id
    let anchor = document.querySelector(id)
    anchor!.scrollIntoView({ block: 'center', behavior: 'smooth' })
  }
}
