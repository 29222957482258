import { Component, Input } from '@angular/core'

@Component({
  selector: 'ui-alg-line-item',
  templateUrl: './ui-alg-line-item.component.html',
  styleUrls: ['./ui-alg-line-item.component.scss'],
})
export class UiAlgLineItemComponent {
  @Input() public item: any

  fullStars(n: number) {
    return Array(n)
  }
  emptyStars(fullStars: number) {
    const STAR_TOTAL = 5
    return Array(STAR_TOTAL - fullStars)
  }
}
