import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'formatCategories',
})
export class FormatCategoriesPipe implements PipeTransform {
  transform(categories: string[] | undefined, ...args: any[]) {
    if (categories === undefined) {
      return []
    }
    return categories.map(category => ({ label: category, value: category }))
  }
}
