import { Component, Input } from '@angular/core'

@Component({
  selector: 'ui-range-slider',
  templateUrl: './ui-range-slider.component.html',
  styleUrls: ['./ui-range-slider.component.scss'],
})
export class UiRangeSliderListComponent {
  @Input() public attribute!: string
  @Input() public min?: number = 0
  @Input() public pips: boolean = true
  @Input() public tooltips: boolean = true
}
