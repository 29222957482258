import { Component, OnDestroy, ViewChild } from '@angular/core'
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { IBetaService } from '@employer/app/services/beta/beta.service.interface'
import { AppConfigService } from '@engineering11/tenant-bootstrap-web'
import { RecaptchaComponent } from 'ng-recaptcha'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { TokenStorage } from 'shared-lib'
@Component({
  templateUrl: './code-wall.component.html',
  styleUrls: ['./code-wall.component.scss'],
})
export class AuthenticationCodeWallComponent implements OnDestroy {
  @ViewChild('captchaRef')
  captchaRef!: RecaptchaComponent
  destroy$: Subject<boolean> = new Subject<boolean>()
  formCode = new UntypedFormGroup({})
  loading = false
  formCodeSubmitted = false
  formError = false
  logoUrl = this.appConfig.config.brand.logoImageDarkUrl

  constructor(
    public router: Router,
    private tokenStorage: TokenStorage,
    private betaService: IBetaService,
    private formBuilder: UntypedFormBuilder,
    private appConfig: AppConfigService
  ) {
    const code = this.tokenStorage.getItem('code')
    // if (code) {
    //   this.router.navigate(['/auth/register'])
    // }
    this.formCode = this.formBuilder.group({
      code: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
    })
    this.formCode.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(changes => {
      this.formEntryValidation(changes.code)
    })
  }

  get f() {
    return this.formCode.controls as {
      [key: string]: UntypedFormControl
    }
  }

  async onSubmit(): Promise<void> {
    this.formCodeSubmitted = true
    if (this.formCode.valid) {
      this.loading = true
      this.captchaRef.execute()
    } else {
      this.formEntryValidation(this.formCode.value.code)
    }
  }

  async resolved($event: string): Promise<void> {
    if ($event) {
      this.formError = false
      await this.processSubmission()
      this.loading = false
    } else {
      this.formError = true
      this.loading = false
    }
  }

  async processSubmission() {
    const { res, serverError } = await this.betaService.validateBetaCode(this.formCode.value.code)

    this.loading = false

    if (serverError || !res) {
      this.captchaRef?.reset()
      this.loading = false
      this.formError = true
      return
    }
    if (res) {
      this.tokenStorage.setItem('code', true)
      this.router.navigate(['registration/register'])
      return
    }
  }

  navigateTo(location: string) {
    if (location === 'authcode') {
      window.location.href = 'https://cnected.com/sign-up/'
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  formEntryValidation(value: string) {
    if (value.length === 0) {
      this.formCode.controls.code.setErrors({ requiredAuthCode: true })
    }
    if (value.length > 0 && value.length < 6) {
      this.formCode.controls.code.setErrors({ invalidAuthCode: true })
    }
  }

  registerTrial() {
    this.tokenStorage.setItem('code', true)
    this.router.navigate(['registration/register'])
  }
}
