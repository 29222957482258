import { Component, OnInit, ViewChild } from '@angular/core'
import { ConfigService } from '@employer/app/services/config.service'

@Component({
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss'],
})
export class HomeStartComponent implements OnInit {
  @ViewChild('templateOne') templateOne: any
  themeCurrent: any

  constructor(private configService: ConfigService) {
    this.themeCurrent = configService.config.theme
  }

  ngOnInit(): void {}

  ngAfterViewInit() {}
}
