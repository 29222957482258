<h1>Home Component</h1>
<h2>Home Component</h2>
<h3>Home Component</h3>
<p>This is where you end up right after logging in. ...like a "dashboard".</p>
<p>
  There is a second view here <a class="color-secondary" [routerLink]="['view2']">(view2)</a> if you need it for any reason. Feel free to delete it if
  not needed.
</p>

<!-- SideDrawer -->
<e11-side-over-container sideOverPosition="right">
  <div class="row">
    <div class="col-xs-12 nopadding-left">A whole flipping view would go in here....</div>
  </div>
  <div class="row">
    <div class="col-xs-12"></div>
  </div>
</e11-side-over-container>

<ng-template #templateOne> Hello, World, one </ng-template>

<ng-template #templateTwo> Hello, World, TWO </ng-template>

<ng-template #templateThree> Hello, World, THREE!!! </ng-template>
