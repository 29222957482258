import { Component, Input } from '@angular/core'
import { RefinementListConnectorParams } from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList'

@Component({
  selector: 'ui-refinement-list',
  templateUrl: './ui-refinement-list.component.html',
  styleUrls: ['./ui-refinement-list.component.scss'],
})
export class UiRefinementListComponent {
  @Input() public attribute!: string
  @Input() public operator: 'or' | 'and' = 'or'
  @Input() public searchable?: boolean
  @Input() public sortBy: RefinementListConnectorParams['sortBy'] = ['name:asc']
  @Input() public showMore = false
  @Input() public showMoreLimit = 20
  @Input() public transform = (items: any[]) => items
}
