import { Component, Input, OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { ROUTES } from '@employer/app/services/navigation.service'
import { ConversationStore, openConversationFilter } from '@engineering11/messaging-web'
import { ViewportService } from '@engineering11/web-utilities'
import { filter } from 'rxjs/operators'
import { ConversationTypes } from 'shared-lib'

@Component({
  selector: 'candidate-pool-nav',
  styleUrls: ['./candidate-pool-nav.component.scss'],
  template: `
    <ul class="lg:e11-flex e11-text-skin-light e11-flex-col e11-h-full lg:e11-divide-y lg:e11-divide-skin-grey/30">
      <li
        (click)="toggleMessagingMenu()"
        [ngClass]="{
          'e11-border-b-transparent e11-bg-skin-white/20 e11-text-skin-white e11-rounded-md': isCandidatePoolMenuOpen,
          'e11-border-b-skin-grey/30': !isCandidatePoolMenuOpen
        }"
        class="e11-flex e11-h-12 e11-px-2 e11-justify-between e11-items-center e11-border-b e11-border-t-skin-grey/30 hover:e11-bg-skin-primary-accent/30 e11-cursor-pointer"
      >
        <div class="e11-flex e11-items-center e11-w-full">
          <span class="material-icons e11-mr-2 md-18" [ngClass]="{ 'e11-text-skin-white': !(lg$ | async) }"> account_circle </span>
          <span class="e11-font-medium e11-text-md e11-relative" [ngClass]="{ 'e11-text-skin-white': !(lg$ | async) }">
            {{ 'Candidate Pool' | translate }}
            <e11-badge
              *ngIf="unreadConversationCount$ | async"
              [color]="'error'"
              [containerClassOverrides]="''"
              [limit]="UNREAD_LIMIT"
              [posAdjustX]="'-4px'"
              [posAdjustY]="'-11px'"
              [size]="'xs'"
              [value]="(unreadConversationCount$ | async) ?? 0"
            ></e11-badge>
          </span>
        </div>
        <span [ngClass]="{ 'nav-icon-active': isCandidatePoolMenuOpen }" class="material-icons-outlined e11-text-skin-extra-light nav-icon">
          expand_more
        </span>
      </li>

      <div
        *ngIf="isCandidatePoolMenuOpen"
        [ngClass]="{ 'nav-candidate-pool-container-active ': isCandidatePoolMenuOpen }"
        class="nav-candidate-pool-container e11-px-2"
      >
        <li
          (click)="navigateTo(ROUTES.CANDIDATE_POOL)"
          class="nav-item e11-w-full e11-mt-2 e11-mb-2 e11-rounded-md e11-pt-2 e11-cursor-pointer"
          [ngClass]="{ 'e11-text-white e11-bg-skin-primary-accent': isActiveRoute(ROUTES.CANDIDATE_POOL), 'e11-text-skin-white': !(lg$ | async) }"
        >
          <div class="e11-flex e11-items-center e11-w-full">
            <span class="e11-font-medium e11-text-md e11-py-0.5 e11-mb-2 e11-pl-2">{{ 'Candidate Search' | translate }}</span>
          </div>
        </li>
        <li
          (click)="navigateTo(ROUTES.CANDIDATE_POOL_MESSAGE)"
          class="nav-item e11-w-full e11-mt-2 e11-mb-2 e11-rounded-md e11-pt-2 e11-cursor-pointer"
          [ngClass]="{
            'e11-text-white e11-bg-skin-primary-accent': isActiveRoute(ROUTES.CANDIDATE_POOL_MESSAGE),
            'e11-text-skin-white': !(lg$ | async)
          }"
        >
          <div class="e11-flex e11-items-center e11-w-full">
            <span class="e11-font-medium e11-text-md e11-py-0.5 e11-mb-2 e11-pl-2 e11-relative">
              {{ 'Candidate Messages' | translate }}
            </span>
            <span *ngIf="unreadConversationCount$ | async" class="e11-h-2 e11-w-2 e11-ml-1 e11-mb-6 e11-rounded-full e11-bg-skin-white"></span>
          </div>
        </li>
      </div>
    </ul>
  `,
})
export class CandidatePoolNavComponent implements OnInit {
  ROUTES = ROUTES
  @Input() isCandidatePoolMenuOpen = false
  selectedRoute: string = ''

  unreadConversationCount$ = this.conversationStore.getUnreadConversationCountForQuery({
    types: [ConversationTypes.CandidatePoolDM, ConversationTypes.CandidatePoolDM],
    customFilter: openConversationFilter,
  })

  UNREAD_LIMIT = 9

  lg$ = this.viewportService.aboveBreakpoint('lg')

  constructor(private conversationStore: ConversationStore, private router: Router, private viewportService: ViewportService) {}

  ngOnInit() {
    this.updateSelectedRoute()
    this.closeMenuIfNotCandidatePool()

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.updateSelectedRoute()
      this.closeMenuIfNotCandidatePool()
    })
  }

  updateSelectedRoute() {
    this.selectedRoute = this.router.url.split('/').pop() || ''
  }

  navigateTo(route: string) {
    this.router.navigate([route])
  }

  toggleMessagingMenu() {
    this.isCandidatePoolMenuOpen = !this.isCandidatePoolMenuOpen
  }

  isActiveRoute(route: string): boolean {
    return this.router.url.includes(route)
  }

  closeMenuIfNotCandidatePool() {
    const candidatePoolRoutes = [ROUTES.CANDIDATE_POOL, ROUTES.CANDIDATE_POOL_MESSAGE]
    this.isCandidatePoolMenuOpen = candidatePoolRoutes.some(route => this.router.url.includes(route))
  }
}
