import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { ConfigService } from '@employer/app/services/config.service'
import { FormCustomValidationService } from 'shared-lib'

@Component({
  selector: 'customer-modal-profile-pic',
  templateUrl: './modal-profile-pic.component.html',
  styleUrls: ['./modal-profile-pic.component.scss'],
})
export class CustomerModalProfilePicComponent implements OnInit {
  themeCurrent: any

  // Forms:
  formAccount = new UntypedFormGroup({})
  formAccountSubmitted = false

  constructor(
    private configService: ConfigService,
    private formBuilder: UntypedFormBuilder,
    private changeDetector: ChangeDetectorRef,
    private customValidator: FormCustomValidationService
  ) {
    this.themeCurrent = configService.config.theme
  }

  registrationForm = this.formBuilder.group({
    file: [null],
  })

  @ViewChild('fileInput')
  el!: ElementRef
  // imageUrl = this.testCustomer.profileUrl
  submitted: boolean = false
  editFile: boolean = true
  removeUpload: boolean = false

  ngOnInit(): void {
    //
  }

  uploadFile(event: any) {
    let reader = new FileReader()
    let file = event.target.files[0]
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file)

      reader.onload = () => {
        // this.imageUrl = reader.result
        this.registrationForm.patchValue({
          file: reader.result,
        })
        this.editFile = false
        this.removeUpload = true
      }
      this.changeDetector.markForCheck()
    }
  }

  removeUploadedFile() {
    let newFileList = Array.from(this.el.nativeElement.files)
    // this.imageUrl = ''
    this.editFile = true
    this.removeUpload = false
    this.registrationForm.patchValue({
      file: [null],
    })
  }

  onSubmit() {
    this.submitted = true
    if (!this.registrationForm.valid) {
      return false
    } else {
      //
      return true
    }
  }
}
