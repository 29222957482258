<div>
  <div class="e11-mb-5">
    <input hidden="true" type="file" id="imageUpload" accept=".png, .jpg, .jpeg, .webp" #fileInput (change)="setImageToCropper($event)" />

    <img *ngIf="logoUrl && !fileForImageCropper" [src]="logoUrl" class="e11-max-h-[500px] e11-m-auto" />

    <e11-image-cropper
      *ngIf="fileForImageCropper"
      (imageCropped)="imageCropped($event)"
      imageContainerClassOverrides="e11-max-h-[500px]"
      [imageFile]="fileForImageCropper"
    >
    </e11-image-cropper>

    <e11-empty-state
      *ngIf="!fileForImageCropper && !logoUrl"
      [icon]="'file_upload'"
      [iconSize]="'md-36'"
      [iconType]="''"
      title="{{ 'Upload a company logo' | translate }}"
      subtitle="{{
        'If a logo is added here, it will appear on the job posts seen by candidates both within the platform and on your public careers page.'
          | translate
      }}"
      (click)="fileInput.click()"
    >
    </e11-empty-state>
  </div>

  <div class="e11-flex e11-flex-wrap-reverse md:e11-flex-nowrap e11-items-center e11-justify-center e11-gap-4 e11-mb-5">
    <div class="e11-w-full md:e11-w-auto" *ngIf="logoUrl && !fileForImageCropper">
      <e11-button
        class="e11-w-full"
        id="button_submit"
        value="{{ 'Delete' | translate }}"
        color="secondary"
        type="button"
        [ghost]="true"
        [icon]="'delete'"
        [display]="'block'"
        (click)="logoRemoved.emit()"
      >
      </e11-button>
    </div>

    <div class="e11-w-full md:e11-w-auto">
      <e11-button
        class="e11-w-full"
        id="button_upload"
        value="{{ 'Upload' | translate }}"
        color="primary-accent"
        type="button"
        [icon]="'file_upload'"
        [iconType]="'outlined'"
        [iconSize]="'md-24'"
        [iconPosition]="'left'"
        (click)="fileInput.click()"
        [display]="'block'"
      >
      </e11-button>
    </div>

    <div class="e11-w-full md:e11-w-auto" *ngIf="croppedImage">
      <e11-button
        class="e11-w-full"
        id="button_save"
        value="{{ 'Save' | translate }}"
        [color]="'primary-accent'"
        type="button"
        [icon]="'save'"
        [iconType]="'outlined'"
        [iconSize]="'md-24'"
        [iconPosition]="'left'"
        [display]="'block'"
        (click)="logoUploaded.emit(croppedImage)"
      >
      </e11-button>
    </div>
  </div>
</div>
