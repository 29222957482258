import { Component, OnInit } from '@angular/core'
import { UntypedFormBuilder } from '@angular/forms'
import { Router } from '@angular/router'
import { ConfigService } from '@employer/app/services/config.service'
import { E11NotificationsService } from '@engineering11/ui-lib/e11-notifications'
import { FormCustomValidationService } from 'shared-lib'

@Component({
  selector: 'customer-manage-settings',
  templateUrl: './manage-settings.component.html',
  styleUrls: ['./manage-settings.component.scss'],
})
export class CustomerManageSettingsComponent implements OnInit {
  themeCurrent: any

  constructor(
    private configService: ConfigService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private customValidator: FormCustomValidationService,
    private notificationsService: E11NotificationsService
  ) {
    this.themeCurrent = configService.config.theme
  }

  ngOnInit(): void {
    // if (!this.testAuthService.isUserSignedIn()) {
    //     this.router.navigate(['/auth/login'])
    // } else {
    // this.testUser = JSON.parse(localStorage.getItem('testUser') || '{}')
    // }
    // this.createFormAccount();
  }

  changeSetting(value: any, setting: string): void {
    //
    // Here we would update the company/customer record directly
  }
}
