import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'app-customer-manage-header',
  template: `
    <div class="e11-w-full e11-flex e11-mb-4">
      <div class="e11-grow">
        <h2 class="e11-text-skin-primary-accent">{{ headerText | translate }}</h2>
        <p *ngIf="subtitle" class="e11-mb-0">{{ subtitle | translate }}</p>
      </div>
      <div class="e11-grow e11-flex e11-items-center e11-justify-end">
        <e11-button
          [color]="'primary-accent'"
          value="{{ buttonText | translate }}"
          [icon]="'add'"
          [iconPosition]="'left'"
          [iconSize]="'md-18'"
          (click)="addClicked.emit()"
        >
        </e11-button>
      </div>
    </div>
  `,
})
export class CustomerManageHeaderComponent {
  @Input() headerText!: string
  @Input() buttonText!: string
  @Input() subtitle?: string

  @Output() addClicked = new EventEmitter()

  constructor() {}
}
