<h3 class="nomargin">{{ 'Non-discrimination Settings' | translate }}</h3>
<p>
  {{
    'Enable non-discrimination settings for your organization. Based on your selections, these items will be masked for all Hiring Managers, Recruiters, or Reviewers accessing the candidates, to help avoid unconscious biases.'
      | translate
  }}
</p>

<e11-panel>
  <div class="row">
    <div class="col-sm-12">
      <div class="row flex-align-center">
        <div class="col-xs-1"></div>
        <div class="col-xs-11">
          <h4>{{ 'Disable Profile Photos' | translate }}</h4>
          <span>{{ 'Choose to view or hide candidates profile picture from the hiring team.' | translate }}</span>
        </div>
      </div>
    </div>
  </div>

  <e11-divider></e11-divider>

  <div class="row">
    <div class="col-sm-12">
      <div class="row flex-align-center">
        <div class="col-xs-1"></div>
        <div class="col-xs-11">
          <h4>{{ 'Audio Only' | translate }}</h4>
          <span>{{
            'Choose to only listen to the candidates audio of their first impression. Video will not display and this will only allow text-based Q&A exchanges.'
              | translate
          }}</span>
        </div>
      </div>
    </div>
  </div>

  <e11-divider class="nomargin"></e11-divider>

  <div class="row">
    <div class="col-sm-12">
      <div class="row flex-align-center">
        <div class="col-xs-1"></div>
        <div class="col-xs-11">
          <h4>{{ 'Disable Candidate Names' | translate }}</h4>
          <span>{{ 'Candidates first and last name will be hidden from the hiring team.' | translate }}</span>
        </div>
      </div>
    </div>
  </div>

  <e11-divider class="nomargin"></e11-divider>

  <div class="row">
    <div class="col-sm-12">
      <div class="row flex-align-center">
        <div class="col-xs-1"></div>
        <div class="col-xs-11">
          <h4>{{ 'Disable Candidate Addresses' | translate }}</h4>
          <span>{{ 'Candidates physical home address will hidden from the hiring team' | translate }}</span>
        </div>
      </div>
    </div>
  </div>

  <e11-divider></e11-divider>

  <div class="row">
    <div class="col-sm-12">
      <div class="row flex-align-center">
        <div class="col-xs-1"></div>
        <div class="col-xs-11">
          <h4>{{ 'Disable School Names' | translate }}</h4>
          <span>{{ 'School names will be hidden from the hiring team' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
</e11-panel>
