<h1>Home View 2</h1>
<p>...just the next view your come across after logging in.</p>
<p><a [routerLink]="['/home/']">Link back to home / "dashboard"</a></p>
<e11-button color="secondary" [value]="'Show Side Drawer'" (click)="sideDrawer.open()" [buttonGroup]="true"> </e11-button>

<!-- SideDrawer -->
<e11-side-over-container #sideDrawer [sideOverPosition]="'right'">
  <div class="row">
    <div class="col-xs-12 nopadding-left">
      <h2>View 2!!!!</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12"></div>
  </div>
</e11-side-over-container>
